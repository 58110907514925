<!-- 日志管理-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="doEdit()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chakan" font-size="27px;"/>
                            <span class="txt">{{msg('查看')}}</span>      
                        </div>         
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出列表')}}</span>      
                        </div>         
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('操作人')">
                        <el-input v-model="scope.query.fullname"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('操作类型')" label-width="80px">
                        <el-input v-model="scope.query.type"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('请求参数')" label-width="80px">
                        <el-input v-model="scope.query.param"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('IP地址')" label-width="80px">
                        <el-input v-model="scope.query.ip"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('日期')" label-width="80px">
                        <el-date-picker  v-model="query.Date" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getHistorys" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border
                export-config
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="historys"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

        <el-dialog :title="dglogTitle" :visible.sync="dialogEditVisible"  :fullscreen="isMinScreen" :close-on-click-modal="false">
            <edit :hist="currHist" v-on:close-dg="closeEditDialog" ref="friendLinkEdit"/>
        </el-dialog>

    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import Edit from "./HistoryEdit.vue";
export default {
    name: 'Permission',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        Edit: Edit
    },
    mounted(){
        let curr = new Date();
        let prevTime =  curr.getTime() - (24 * 3600 * 1000);
        let prev= new Date(prevTime);
        this.query.Date = [this.$kit.fmtDateTime(prev), this.$kit.fmtDateTime(curr)]
        this.getHistorys();
    },
    data(){
        return {
            query:{
                type: null,
                fullname: null,
                param:null,
                ip:null,
                Date: [], 
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },

            showQuery: false,
            columns: [
                { type: 'checkbox', minWidth: 20 },
                {field: "id", title: this.msg("ID"), minWidth: 100, sortable: true},
                {field: "type", title: this.msg("操作类型"), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: "ip", title: this.msg("IP地址"), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: "fullname", title: this.msg("操作人"), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: "url", title: this.msg("请求URL"), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: "param", title: this.msg("请求参数"), minWidth: 200, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: "created", title: this.msg("创建时间"), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
            ],
            historys: [],      //权限列表
           
            dgTitle: '',
            dgVisible: false,
            curr: null,
            dglogTitle:null,
            currHist:null,
            dialogEditVisible:false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        
        getHistorys(refresh){
            var _this = this;
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.history.list, params)
                .then((result) => {
                    if(result.status){
                        _this.historys = result.data.list;
                        _this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getHistorys();
        },
        doEdit(){

            var _this = this;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            _this.currHist = selectes[0];
            _this.dglogTitle = this.msg("查看日志");
            _this.dialogEditVisible = true;

        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getHistorys();
            }
            this.dialogEditVisible = false;
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
    }
    
}
</script>

<style scoped lang="scss">

</style>
